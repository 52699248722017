import { AxiosRequestConfig } from "axios";


export const HttpMethod = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
} as const;

export type HttpMethodType = typeof HttpMethod[keyof typeof HttpMethod];

export const API_CONFIG = {
  BASE_URL: 'https://qa.entrycall.ca',
  ENDPOINTS: {
    AUTHENTICATION: {
      OTP: {
        path: '/auth/otp',
        method: HttpMethod.POST
      },
      OTP_VERIFY: {
        path: '/auth/otp/verify',
        method: HttpMethod.POST
      },
      REFRESH: {
        path: '/auth/refresh',
        method: HttpMethod.GET
      },
    },
    RESIDENTS: {
      GET_ALL: {
        path: '/residents/',
        method: HttpMethod.GET
      },
      GET_BY_ID: {
        path: '/residents/{id}',
        method: HttpMethod.GET
      },
      UPDATE: {
        path: '/residents/{id}',
        method: HttpMethod.PUT
      },
      CREATE: {
        path: '/residents/',
        method: HttpMethod.POST
      },
      DELETE: {
        path: '/residents/{id}',
        method: HttpMethod.DELETE
      }
    },
    PROPERTIES: {
      GET_ALL: {
        path: '/properties/',
        method: HttpMethod.GET
      },
      WEATHER: {
        path: '/weather',
        method: HttpMethod.GET
      },
    },
    MESSAGES: {
      GET_ALL: {
        path: '/messages',
        method: HttpMethod.GET
      },
      GET_BY_ID: {
        path: '/messages/{id}',
        method: HttpMethod.GET
      },
      UPDATE: {
        path: '/messages/{id}',
        method: HttpMethod.PUT
      },
      CREATE: {
        path: '/messages',
        method: HttpMethod.POST
      },
      DELETE: {
        path: '/messages/{id}',
        method: HttpMethod.DELETE
      },
      ACTION: {
        path: '/messages/{message}/action',
        method: HttpMethod.PATCH 
      },
      READ: {
        path: '/messages/{message}/read',
        method: HttpMethod.PATCH 
      },
      ARCHIVE: {
        path: '/messages/{message}/archive',
        method: HttpMethod.PATCH 
      },
      UNARCHIVE: {
        path: '/messages/{message}/unarchive',
        method: HttpMethod.PATCH 
      }
    },
    ANNOUNCEMENTS: {
      GET_ALL: {
        path: '/announcements',
        method: HttpMethod.GET
      },
      GET_BY_ID: {
        path: '/announcements/{id}',
        method: HttpMethod.GET
      },
      UPDATE: {
        path: '/announcements/{id}',
        method: HttpMethod.PUT
      },
      CREATE: {
        path: '/announcements',
        method: HttpMethod.POST
      },
      DELETE: {
        path: '/announcements/{id}',
        method: HttpMethod.DELETE
      }
    },
    STREAM: {
      GET_ALL: {
        path: '/stream',
        method: HttpMethod.GET
      },
      GET_BY_ID: {
        path: '/stream/{id}',
        method: HttpMethod.GET
      },
      UPDATE: {
        path: '/stream/{id}',
        method: HttpMethod.PUT
      },
      CREATE: {
        path: '/stream',
        method: HttpMethod.POST
      },
      DELETE: {
        path: '/stream/{id}',
        method: HttpMethod.DELETE
      }
    },
    TOKEN: {
      GET_ALL: {
        path: '/token',
        method: HttpMethod.GET
      },
      GET_BY_ID: {
        path: '/token/{id}',
        method: HttpMethod.GET
      },
      UPDATE: {
        path: '/token/{id}',
        method: HttpMethod.PUT
      },
      CREATE: {
        path: '/token',
        method: HttpMethod.POST
      },
      DELETE: {
        path: '/token/{id}',
        method: HttpMethod.DELETE
      }
    },
  },
};

export type AxiosPayload = {
  loginId: string | null;
};

export type VerificationPayload = {
  loginId: string;
  verifyCode: string;
  email?: string;
  phone?: string;
};

   
export type RefreshTokenParams = {
  token: string;
  refreshToken: string;
};


 // Start of Selection
 export type AuthTenantParams = {
   token: string | undefined | null;
   tenantId: string | undefined | null;
 };


 export type AxiosPublicRequestParams<T = unknown, R = unknown> = {
  config?: AxiosRequestConfig;
  url: string;
  method: string;
  data?: T | undefined;
};

export type AxiosApiRequestParams = {
  config: AxiosRequestConfig;
  payload: AxiosPayload | VerificationPayload,
};